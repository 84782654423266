<template>
  <div>
    <vue-headful
      title="Наши контакты"
      description="Контакты"
    />
    <div class="section pa-9 section-main-100 base-content">
      <h2 class="pt-2 text-h5 text-uppercase weight-900">Контакты</h2>
      <div class="mt-6 d-flex flex-row justify-space-between mb-2 info-block">
        <p>
          <span class="title font-weight-bold">Директор</span><br>
          Меркурьева Мария Викторовна<br>+7(988) 257-44-45
        </p>
        <p>
          <span class="title font-weight-bold">Руководитель отдела кадров</span><br>
          Евгений Александрович<br>+7(909)
          407-85-81</p>
        <div>
          <p class="title font-weight-bold mb-0">Адрес</p>
          <p class="mb-0">пр. Будённовский 120/1, офис 118</p>
          <p><a href="mailto:an.merkuriy@yandex.ru">an.merkuriy@yandex.ru</a></p>
        </div>
      </div>

      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3Afa31645adc9ac31f36689f15d7ef30b37f0e82c4dbddd9a169eaa2d9c5a344d6&amp;source=constructor"
        width="100%"
        height="527"
        frameborder="0"
      ></iframe>

    </div>
  </div>
</template>

<script>

export default {
  name: 'Contacts'
}
</script>

<style scoped>
  @media (max-width: 700px) {
    .info-block {
      flex-direction: column !important;
      padding-left: 4px;
    }
    .section {
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
</style>
